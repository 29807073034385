.c-datepicker__header-date {
    background: #00bcd4;
    height: unset;
    padding: 16px 0;
}

.c-datepicker__clock {
    position: relative;
    width: 200px;
    height: 372px;
    padding: 0;
    border-radius: 50%;
    list-style: none;
    font-size: 14px;
    line-height: 50px;
    padding: 160px 0 20px 0;
    margin: 0 auto;
}

.js-cancel {
    display: none;
}

.c-datepicker {
    zoom: 90%;
}