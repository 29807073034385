body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    overflow: hidden
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html {
    overflow: hidden
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
}

#root>div {
    zoom: 100%;
}

.markdown-container {
    p {
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-top-width: 0px;
        box-sizing: border-box;
        display: inline;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-weight: 400;
        height: auto;
        line-height: 20px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        overflow-wrap: break-word;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        text-decoration-color: rgb(11, 67, 89);
        text-decoration-line: none;
        text-decoration-style: solid;
        text-size-adjust: 100%;
        unicode-bidi: isolate;
        white-space: pre-wrap;
        width: auto;
        -webkit-box-direction: normal;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

// =================================================
// Error page style
// =================================================

.error-page-container {
    height: 100vh !important;
}

// =================================================
// Text input style
// =================================================

input {
    outline: none;
}

// =================================================
// App intro style
// =================================================

.app-intro-container>div {
    overflow-x: hidden;
}

.app-intro-item-container {
    height: 100vh !important;
}


// =================================================
// Scale Chat container
// =================================================

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .bubble-container {
        max-width: 60%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .chat-container>div>div:first-child {
        width: 768px;
        margin: auto;
    }

    .bubble-container {
        max-width: 60%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .chat-container>div>div:first-child {
        width: 768px;
        margin: auto;
    }

    .bubble-container {
        max-width: 60%;
    }
}


// =================================================
// Chat container
// =================================================

.chat-container {
    background-color: transparent;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex: 1;
    position: absolute;
    top: 60px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.chat-container>div>div:first-child {
    flex: 1;
    flex-direction: column;
}

// =================================================
// Map bubble container
// =================================================

.message-map .gm-style {
    position: absolute;
    z-index: 0;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    padding: 0px;
    border-width: 0px;
    margin: 0px;
    height: 130px !important;
}